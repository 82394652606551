/* Define CSS Variables for Colors and Other Styles */
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

:root {
  --app-text-primary: #333;
  --app-text-secondary: #736d67;
  --app-text-custom1: #739d78;
  --app-text-custom2: #f2a961;
  --app-text-custom3: #8c61f2;
  --app-text-custom4: #d1456e;
  --app-text-custom5: #7c525f;
  --app-bg-primary: #025f0d;
  --app-bg-secondary: #739d78;
  --app-header-color: #222;
  --app-card-header-primary: #f2618c;
  --app-card-header-text-color: #fff;
  --app-btn-primary: #8c61f2;
  --app-btn-secondary: #739d78;
}

/* Large devices (desktops, 768px and up) */
@media (min-width: 768px) {
  h1 {
    font-size: 2.058em;
  }

  h2 {
    font-size: 1.8248em;
  }

  h3 {
    font-size: 1.6179em;
  }

  h4 {
    font-size: 1.4346em;
  }

  h5 {
    font-size: 1.272em;
  }

  h6 {
    font-size: 1.1278em;
  }

  small {
    font-size: 0.8867em;
  }
}

/* Medium devices (tablets, 600px to 768px) */
@media (min-width: 600px) and (max-width: 768px) {
  body {
    padding: 20px;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.42em;
  }

  h4 {
    font-size: 1.26em;
  }

  h5 {
    font-size: 1.12em;
  }

  h6 {
    font-size: 1em;
  }

  small {
    font-size: 0.8em;
  }
}

/* Small devices (mobile, 600px and down) */
@media (max-width: 600px) {
  body {
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.15em;
  }

  h4 {
    font-size: 1.02em;
  }

  h5 {
    font-size: 0.9em;
  }

  h6 {
    font-size: 0.8em;
  }

  small {
    font-size: 0.7em;
  }
}

/* Base Styles */
body {
  color: var(--text-color);
  background-color: var(--background-color);
  font-family: "Poppins", sans-serif, "Roboto", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif Segoe UI, Roboto, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
  padding-top: 3.5em;
  /* Ensure the body covers the entire viewport height. */
  min-height: 100vh;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-color);
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.card-header {
  background-color: var(--card-header-bg-color);
  color: var(--card-header-text-color);
  padding: 1rem;
  font-size: 1.25rem;
}

.navbar-brand {
  font-family: Roboto;
  font-size: 1.5em;
}

.app-bg-header {
  background-color: var(--app-bg-primary);
}

.app-bg-primary {
  background-color: var(--app-bg-primary);
}
.app-bg-secondary {
  background-color: var(--app-bg-secondary);
}

.app-text-primary {
  color: var(--app-text-primary);
}
.app-text-secondary {
  color: var(--app-text-secondary);
}
.app-text-custom1 {
  color: var(--app-text-custom1);
}
.app-text-custom2 {
  color: var(--app-text-custom2);
}
.app-text-custom3 {
  color: var(--app-text-custom3);
}

.card-header {
  width: 100%;
}

.custom-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--app-btn-secondary);
  color: white;
  border: none;
  cursor: pointer; /* Change cursor to pointer on hover */
  position: relative; /* Required for pseudo-element positioning */
}

.custom-button span {
  display: inline-block;
  transition: transform 0.3s ease; /* Adjust timing as needed */
}

.custom-button:hover span {
  transform: translateX(-10px); /* Adjust distance as needed */
}

/* Pseudo-element for the arrow */
.custom-button:hover {
  background-color: var(--app-btn-secondary);
}

.custom-image {
  /* Smooth scaling transition */
  transition: transform 0.3s ease;
}

.custom-image:hover {
  /* Scales the card up to 105% when hovered */
  transform: scale(1.05);
}

.custom-bullet {
  position: relative;
  list-style-type: none;
  padding-left: 1.5em;
}

.custom-bullet::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #007bff; /* adjust color as needed */
  border-radius: 50%;
}

.custom-bullet span {
  font-size: 1.25rem; /* adjust as needed */
  font-weight: 500; /* adjust as needed */
}

.custom-card {
  background-color: #f8f9fa; /* Light gray */
  border-radius: 20px; /* Rounded corners */
  overflow: hidden; /* Ensures child elements don't overflow the rounded corners */
  /* transition: transform 0.3s ease; Smooth scaling transition */
}

/* .custom-card:hover {
  transform: scale(1.05); Scales the card up to 105% when hovered
} */

.custom-card .card-header {
  background-color: var(--app-card-header-primary); /* Primary color */
  color: #fff; /* White text */
  font-size: 1.25rem; /* Larger text */
  font-weight: bold; /* Bold text */
}

.custom-card .card-body {
  padding: 0.5rem; /* Larger padding */
}

.custom-card .li-12em {
  color: #6c757d; /* Darker gray text */
}

.all-ts {
  transition: all var(--cnvs-transitions);
}

.custom-card-transition {
  /* Smooth scaling transition */
  transition: transform 0.3s ease;
}
.custom-card-transition:hover {
  /* Scales the card up to 105% when hovered */
  transform: scale(1.015);
}

.custom-link {
  text-decoration: none;
  cursor: pointer;
  color: var(--app-text-custom5);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-styled {
  line-height: 0.7;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.li-gap {
  display: block;
  margin-bottom: 1em;
}

footer {
  /* background-color: var(--app-bg-pc1); */
  padding-top: 2em;
  padding-bottom: 2em;
  /* text-align: center; */
  margin-top: 2rem;
  width: 100%;
  font-size: 80%;
  background-color: #f5f5f5;
}

.vh-50 {
  height: 50vh;
}

.h-shadow-sm:hover,
.shadow-sm {
  box-shadow: var(--cnvs-box-shadow-sm) !important;
}
.rounded-5 {
  border-radius: 0.5rem !important;
}

.h-translate-y-sm:hover,
.translate-y-sm {
  transform: translateY(-3px) !important;
}

.h-translate-y:hover,
.translate-y {
  transform: translateY(-6px) !important;
}

.h-translate-y-lg:hover,
.translate-y-lg {
  transform: translateY(-10px) !important;
}

.h-translate-x-sm:hover,
.translate-x-sm {
  transform: translateX(-3px) !important;
}

.h-translate-x:hover,
.translate-x {
  transform: translateX(-6px) !important;
}

.h-translate-x-lg:hover,
.translate-x-lg {
  transform: translateX(-10px) !important;
}

.h-translate-x-n-sm:hover,
.translate-x-n-sm {
  transform: translateX(3px) !important;
}

.h-translate-x-n:hover,
.translate-x-n {
  transform: translateX(6px) !important;
}

.h-translate-x-n-lg:hover,
.translate-x-n-lg {
  transform: translateX(10px) !important;
}

.h-scale-sm:hover,
.scale-sm {
  transform: scale(1.025) !important;
}

.h-scale:hover,
.scale {
  transform: scale(1.05) !important;
}

.h-scale-lg:hover,
.scale-lg {
  transform: scale(1.1) !important;
}

.h-scale-n-sm:hover,
.scale-n-sm {
  transform: scale(0.975) !important;
}

.h-scale-n:hover,
.scale-n {
  transform: scale(0.95) !important;
}

.h-scale-n-lg:hover,
.scale-n-lg {
  transform: scale(0.9) !important;
}

.transform-ts {
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
