.theme-pills {
  margin-bottom: 20px;
}

.theme-pills .nav-link {
  color: #666;
}

.theme-pills .nav-link.active {
  color: #337ab7;
  background-color: #fff;
  border-color: #337ab7;
}

.card {
  margin-bottom: 20px;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card h2 {
  font-weight: bold;
}

.card p {
  font-size: 16px;
  color: #666;
}

.title-sidebox {
  text-decoration: underline;
  font-size: large;
}

.subheading {
  font-size: 1.25rem; /* Adjust as needed */
  font-weight: normal; /* Adjust as needed */
}
