.hero {
  background-image: url("https://via.placeholder.com/1920x1080");
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.card-group {
  display: flex;
  justify-content: center;
}

.card {
  flex: 0 0 33%; /* Adjust based on padding/margins */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card img {
  height: 200px;
  object-fit: cover;
}

.feature-card {
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.testimonial-card {
  border: #fff 2px solid;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #000; /* Replace with actual colors and shapes */
}

.carousel-indicators [data-bs-target] {
  background-color: #000; /* Replace with actual colors */
}
