.carousel {
  overflow: hidden;
  position: relative;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex: 0 0 100%;
  max-width: 100%;
  /* Adjust height as needed */
  max-height: 300px;
  background-color: white;
}

.carousel-container {
  position: relative;
}

.carousel-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2); /* semi-transparent black */
  color: white;
  padding: 10px;
}
.search-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Add more styles as needed */
}

.carousel-img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff; /* adjust color as needed */
}
