ul {
  margin: 0 auto;
  /* text-align: center; */
}

li {
  display: inline-block;
  vertical-align: top;
}

.month-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust as needed */
  gap: 1em; /* Adjust as needed */
}

.district-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Adjust as needed */
  gap: 1em; /* Adjust as needed */
}
